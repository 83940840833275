/* eslint-disable react-hooks/exhaustive-deps */

/**
 * @ComponentFor BambuserBlockViewModel
 */

import * as React from 'react';
import BambuserBlockViewModel from './BambuserBlockViewModel.type';
import { addToCart, updateCartItemQuantity } from 'Cart/action-creators';
import connect from 'Shared/connect';
import { loadScript, shallowEquals, postJson, URLX, replaceState } from '@avensia/scope';
import bambuserProduct from './BambuserProduct.type';
import CartItemType from '/Cart/Models/CartItemViewModel.type';
import { CartEventLocation } from 'TrackingInformation/tracking-types';
import { StyledProps } from '@glitz/react';
import ImageBlock from 'Shared/Image';
import { epiPropertyValue } from '@avensia/scope-episerver';

type PlayerType = {
  current: any;
};

type ConnectedPropType = {
  culture: string;
  checkoutPageUrl: string;
  cartItems: CartItemType[];
};

type DispatchPropType = {
  addToCart: (code: string, quantity: number, ticket: string, location: CartEventLocation) => Promise<void>;
  updateCartItemQuantity: (item: CartItemType, quantity: number) => Promise<void>;
};

type PropType = BambuserBlockViewModel &
  ConnectedPropType &
  DispatchPropType &
  StyledProps & {
    checkoutUrl: string;
  };

type StateType = {
  apiIsLoaded: boolean;
  bambuserShouldLoad: boolean;
  bambuserShouldPlay: boolean;
};

class BambuserBlock extends React.Component<PropType, StateType> {
  player: PlayerType = { current: null };
  componentIsMounted: boolean = true;
  state = {
    apiIsLoaded: false,
    bambuserShouldLoad: false,
    bambuserShouldPlay: false,
  };
  isCached: boolean;

  componentDidMount() {
    if (!this.state.apiIsLoaded) {
      (window as any).onBambuserLiveShoppingReady = (player: any) => {
        player.configure({
          locale: this.props.culture,
          currency: this.props.currency,
          buttons: {
            dismiss: player.BUTTON.MINIMIZE,
          },
        });

        player.on(player.EVENT.ADD_TO_CART, (addedItem: any, callback: (arg: boolean) => void) => {
          this.props
            .addToCart(addedItem.sku, 1, '', 'Bambuser')
            .then(() => {
              replaceState(null, { includeAppShellData: true });
              callback(true);
            })
            .catch(() => callback(false));
        });

        player.on(player.EVENT.UPDATE_ITEM_IN_CART, (updatedItem: any, callback: (arg: boolean) => void) => {
          this.props.cartItems.forEach((item) => {});
          const item = this.props.cartItems.find((item) => item.code === updatedItem.sku);
          if (!!item) {
            this.props
              .updateCartItemQuantity(item, updatedItem.quantity)
              .then(() => callback(true))
              .catch(() => callback(false));
          }
        });

        player.on(player.EVENT.READY, () => {
          this.player.current = player;
        });

        player.on(player.EVENT.CHECKOUT, () => {
          player.showCheckout(window.location.origin + this.props.checkoutPageUrl);
          this.setState({ bambuserShouldLoad: false });
        });

        player.on(player.EVENT.CLOSE, () => {
          player.close();
          this.setState({ bambuserShouldLoad: false });
        });

        player.on(player.EVENT.PROVIDE_PRODUCT_DATA, async (event: any) => {
          const productSkus = event.products.map((p: any) => p.ref);
          const correctedProducts = await postJson('/bambuserblock/getproducts', productSkus);

          correctedProducts.forEach((product: bambuserProduct) => {
            const eventCurrentProduct: any = event.products.find(
              (eventProduct: any) => eventProduct.ref === product.productId,
            );
            if (eventCurrentProduct.id) {
              player.updateProduct(eventCurrentProduct.id, (factory: any) => {
                return factory.product((p: any) =>
                  p
                    .brandName(product.brandName)
                    .defaultVariationIndex(0)
                    .description(product.description)
                    .name(product.name)
                    .sku(product.sku)
                    .variations((v: any) =>
                      product.variations.map((variation) =>
                        v()
                          .attributes(
                            (a: any) =>
                              a ??
                              a
                                .colorName(variation.attributes.colorName)
                                .colorHexCode(variation.attributes.colorHexCode),
                          )
                          .imageUrls(p.imageUrls)
                          .name(variation.name)
                          .sku(variation.sku)
                          .sizes((s: any) =>
                            variation.sizes.map((size) =>
                              s()
                                .name(size.name)
                                .inStock(size.inStock)
                                .price((p: any) =>
                                  p
                                    .currency(size.price.currency)
                                    .current(size.price.current)
                                    .original(size.price.original),
                                )
                                .sku(size.sku),
                            ),
                          ),
                      ),
                    ),
                );
              });
            }
          });
        });
      };
      this.setState({ apiIsLoaded: true });
    }

    if (this.props.eventId !== null) {
      this.setState({ bambuserShouldLoad: true });
    }
    return () => {
      this.componentIsMounted = false;
    };
  }

  shouldComponentUpdate(nextProps: PropType, nextState: StateType) {
    const shouldUpdate = !shallowEquals(this.state, nextState);
    if (!shouldUpdate) {
      console.info('Ignoring update to Bambuser block');
    }
    return shouldUpdate;
  }

  componentDidUpdate() {
    if (this.state.apiIsLoaded && this.state.bambuserShouldLoad) {
      (async () => {
        await loadScript('https://lcx-embed.bambuser.com/vuxen/embed.js');
        if (this.state.bambuserShouldPlay && this.componentIsMounted) {
          (window as any).initBambuserLiveShopping(this.props.eventId);
        }
      })();
      return () => {
        if (this.player.current) {
          this.destroyPlayer();
        }
      };
    }
  }

  destroyPlayer = () => {
    this.player.current && this.player.current.destroy();
    this.player.current = null;
  };

  onClick = () => {
    this.setState({
      bambuserShouldLoad: true,
      bambuserShouldPlay: true,
    });
  };

  checkIfCachedByBrowser = (image: HTMLImageElement) => (this.isCached = this.assumeCachedByBrowser(image));

  assumeCachedByBrowser(image: HTMLImageElement) {
    if (!image) {
      return false;
    }
    const url = new URLX(image.src);
    // Firefox and IE has a bug where svg reports 0 for natural size in some cases
    if (/\.svg/.test(url.pathname)) {
      return false;
    }
    return image.complete && image.naturalWidth > 0;
  }

  render() {
    const imgUrl = epiPropertyValue(this.props.imageBlockViewModel.block.imageUrl);
    return (
      this.props.eventId && (
        <div onClick={() => this.onClick()} style={{ cursor: 'pointer' }}>
          <ImageBlock src={!!imgUrl && imgUrl.url} />
        </div>
      )
    );
  }
}

export default connect(
  (state): ConnectedPropType => ({
    culture: state.appShellData.culture,
    checkoutPageUrl: state.appShellData.siteSettings.checkoutPage?.url,
    cartItems: state.appShellData.cart.items,
  }),
  (dispatch): DispatchPropType => ({
    addToCart: (code, quantity, ticket, location) => dispatch(addToCart(code, quantity, ticket, location, false)),
    updateCartItemQuantity(item: CartItemType, quantity: number) {
      return dispatch(updateCartItemQuantity(item.code, quantity, 'Bambuser'));
    },
  }),
)(BambuserBlock);
